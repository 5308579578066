// Import the necessary ActionCable methods
import { createConsumer } from "@rails/actioncable"

export const initializeBeaconChannel = () => {

  if (typeof Beacon === 'undefined') {
    window.Beacon = {};
  }

  let beaconConsumer;
  document.addEventListener('DOMContentLoaded', function() {
    // Retrieve the token from the meta tag
    const token = document.querySelector('meta[name="websocket-token"]').getAttribute("content");
    const url = `/cable?websocket_token=${token}`;

    // Check if customConsumer already exists, if not, create a new one
    if (!beaconConsumer) {
      beaconConsumer = createConsumer(url);
      Beacon.beaconConsumer = beaconConsumer; // Ensure it is available globally
    } else {
      beaconConsumer = Beacon.beaconConsumer; // Use the existing global customConsumer
    }

    if(!Beacon.subscription) {
      Beacon.subscription = beaconConsumer.subscriptions.create({channel: "BeaconChannel", channel_id: 1}, {
        connected() {
          console.log('Beacon subscription connected');
          this.beaconInterval = setInterval(() => {
            console.log('beacon');
            this.perform('sendBeacon');
          }, 60 * 1000);
        },
        disconnected() {
          console.log('Beacon subscription disconnected');
          clearInterval(this.beaconInterval);
        },
        received(data) {
          if(data.type === 'attendance') {
            //console.log("data.type === 'attendance'")
            //updateAttendanceList(data.users, data.extras);
          } else {
            //const messagesElement = document.getElementById("messages");
            //const newMessage = document.createElement("div");
            //newMessage.classList.add("message");

            //const nameSpan = document.createElement("strong");
            //nameSpan.innerText = data.first_name + ":";
            //newMessage.appendChild(nameSpan);

            //const contentSpan = document.createElement("span");
            //contentSpan.innerText = " " + data.message;
            //newMessage.appendChild(contentSpan);

            //messagesElement.appendChild(newMessage);
            //removeMessage(8);
          }
        }
      });
      console.log("Beacon subscription initialized.")
    } else {
      console.log('Beacon subscription already initialized.');
    }

    //const sendButton = document.getElementById("sendButton");
    //if (sendButton) {
    //  sendButton.addEventListener("click", sendMessage);
    //}
  });

  window.sendBeacon = function() {
    //var messageContent = document.getElementById('chatMessage').value;
    //if(Messaging.subscription) {
    //  Messaging.subscription.perform('sendData', {message: messageContent});
    //  document.getElementById('chatMessage').value = '';
    //} else {
    //  console.error("Messaging subscription is not initialized yet.");
    //}
  }

  window.addEventListener('beforeunload', () => {
    // Ensure the subscription is cleaned up when the page unloads
    if(Beacon.subscription){
      Beacon.subscription.unsubscribe();
      console.log('Beacon subscription disconnected due to navigation.');
    }

    // Optionally, also disconnect the consumer if you want to clean up the connection entirely
    if(beaconConsumer){
      beaconConsumer.disconnect();
      console.log('Beacon consumer disconnected due to navigation.');
    }
  });

};